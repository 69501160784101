/*
 * @mixin 'lib-css-box-sizing-border-box-reset'
 * @desc apply box-sizing: border-box for current element and all nested elements
 * @example
 *      .box {
 *        @include lib-css-box-sizing-border-box-reset();
 *      }
 */
/**
 * Function to return a mix color with white
 */
/**
 * Function to return a mix color with black
 */
/**
 * Mixin for hide content visually
 * Recommendation: when you call this mixin, use a 'position: relative' rule
 * into a close parent container to limit 'position: absolute' effect
 */
/**
 * Mixin for force 1 line text with '...' truncation
 * TODO: check https://caniuse.com/#search=hyphen for future method of hyphenation
 */
html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  /* stylelint-disable */
  font-family: monospace, monospace;
  /* stylelint-enable */
  font-size: 1em; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

button,
input,
select,
textarea {
  font: inherit; }

optgroup {
  font-weight: bold; }

button,
input,
select {
  overflow: visible; }

button,
input,
select,
textarea {
  margin: 0; }

button,
select {
  text-transform: none; }

[type="button"],
[type="reset"],
[type="submit"],
button {
  cursor: pointer; }

[disabled] {
  cursor: default; }

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  appearance: button; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  appearance: field; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; }

.page-print .logo {
  float: none;
  display: block;
  text-align: left; }

@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    -webkit-filter: none !important;
    filter: none !important; }
  a,
  a:visited {
    text-decoration: underline !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  .table-wrapper table {
    width: 100%;
    table-layout: fixed; }
    .table-wrapper table td {
      width: auto; }
    .table-wrapper table > tfoot > tr:first-child {
      border-top: 1px solid #999; }
  .box,
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 1cm; }
  .block-content,
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  .block-content {
    page-break-before: avoid; }
  .block-title,
  h2,
  h3 {
    page-break-after: avoid; }
  .nav-toggle {
    display: none !important; }
  .sidebar,
  .nav-sections,
  .header.content > *[class],
  .panel.wrapper > *[class],
  .footer.content > *[class] {
    display: none; }
  .logo,
  .footer .copyright {
    display: block !important;
    margin: 10px 0; }
  .order-details-items .order-items .order-gift-message:not(.expanded-content) {
    visibility: visible;
    height: auto; }
  .column.main {
    width: 100% !important;
    float: none !important; }
  .breadcrumbs {
    margin: 0 auto;
    text-align: right; }
  .footer.content {
    padding: 0; } }

.print-header-logo {
  position: absolute;
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  padding-top: 0.3rem;
  max-width: 2.27rem; }

@media print {
  .print-only {
    display: block !important; }
    .print-only-inline-flex {
      display: inline-flex !important; }
  .screen-only,
  .header__wrapper,
  .page-header,
  .breadcrumbs,
  .dc__link-back,
  .backToProductLink,
  .page-footer,
  .zendeskchat,
  .product-availability-info {
    display: none !important; }
  .print-footer {
    display: block;
    font-size: 1rem;
    margin-top: 20px;
    padding: 0 10px; }
  .page-wrapper {
    padding-top: 0 !important; }
    .page-wrapper .page.messages {
      display: none; }
    .page-wrapper .columns {
      display: block; }
  .column.main {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start; }
    .column.main .block div {
      break-inside: avoid; }
  .print-header-logo {
    margin: 0;
    position: static;
    transform: none;
    max-width: 12.6rem;
    padding: 1.5rem 0 0 1.5rem; }
  .dc__form-quantity-picker_input-wrapper {
    width: auto;
    border: none; }
  .dc__order-address-area [data-collapsible='true'] > [role='tab'] > button {
    display: none !important; }
  .dc__order-address-area [data-collapsible='true'] > [role='tabpanel'] {
    display: flex !important; }
  .checkout-cart-index .column.main {
    padding: 0 2rem !important; }
  .checkout-cart-index .page.messages {
    display: none; }
  .checkout-cart-index .block.dc__dashboard_info .dc__box_content_title strong {
    color: #333333 !important; }
  .checkout-cart-index .block.block-dashboard-info {
    display: initial; }
    .checkout-cart-index .block.block-dashboard-info .block-title {
      display: none; }
    .checkout-cart-index .block.block-dashboard-info .block-content {
      margin: 2rem;
      display: flex;
      justify-content: center; }
      .checkout-cart-index .block.block-dashboard-info .block-content .box {
        border: 0.1rem #c7c6c4 solid;
        padding: 2rem;
        border-radius: 0.5rem; }
        .checkout-cart-index .block.block-dashboard-info .block-content .box .box-title {
          font-size: 1.9rem; }
          .checkout-cart-index .block.block-dashboard-info .block-content .box .box-title span {
            color: #99342b !important; }
      .checkout-cart-index .block.block-dashboard-info .block-content .box-newsletter {
        margin-left: 2rem; }
        .checkout-cart-index .block.block-dashboard-info .block-content .box-newsletter .box-content {
          line-height: 1.4; }
      .checkout-cart-index .block.block-dashboard-info .block-content .box-information .box-content {
        line-height: 1.4; }
      .checkout-cart-index .block.block-dashboard-info .block-content .box-actions {
        display: none; }
      .checkout-cart-index .block.block-dashboard-info .block-content .box-phone {
        display: block; }
      .checkout-cart-index .block.block-dashboard-info .block-content .mobile-phone {
        display: none !important; }
  .checkout-cart-index header {
    position: relative; }
    .checkout-cart-index header a {
      display: none; }
    .checkout-cart-index header .logo {
      margin: 2rem; }
  .checkout-cart-index a {
    text-decoration: none !important; }
  .checkout-cart-index .cart-header {
    display: none; }
  .checkout-cart-index .cart-container {
    position: relative;
    display: block; }
    .checkout-cart-index .cart-container .cart-date {
      display: block;
      margin: 0 2rem;
      background-color: #99342b !important;
      -webkit-print-color-adjust: exact;
      padding: 1rem;
      color: #fff !important;
      font-size: 1.6rem;
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .checkout-cart-index .cart-container .cart-summary {
      border: inherit;
      box-shadow: inherit;
      margin-bottom: 0; }
      .checkout-cart-index .cart-container .cart-summary-container {
        margin: 0; }
      .checkout-cart-index .cart-container .cart-summary .block.discount {
        display: none; }
      .checkout-cart-index .cart-container .cart-summary .checkout-methods-items {
        display: none; }
      .checkout-cart-index .cart-container .cart-summary .summary,
      .checkout-cart-index .cart-container .cart-summary .convertwishlist {
        display: none; }
      .checkout-cart-index .cart-container .cart-summary .cart-totals .table-wrapper .data.table tr.summary__shipping-info {
        display: none; }
      .checkout-cart-index .cart-container .cart-summary .cart-totals .table-wrapper .data.table tr.grand.totals th {
        font-size: 1.5rem; }
    .checkout-cart-index .cart-container .summary.title {
      display: none; }
    .checkout-cart-index .cart-container .cart-summary-wrapper {
      right: 2rem;
      width: 50%;
      margin: 0;
      margin-left: auto; }
      .checkout-cart-index .cart-container .cart-summary-wrapper .cart-summary-footer {
        display: none; }
    .checkout-cart-index .cart-container .form-cart {
      float: inherit;
      width: inherit;
      margin: 0 2rem;
      border-width: 0 0.1rem 0.1rem;
      border-color: #d2d2d2;
      border-style: solid; }
  .checkout-cart-index .cart.main.actions {
    display: none; }
  .checkout-cart-index .page-footer {
    display: none; }
  .checkout-cart-index .cart.table-wrapper .items thead th {
    font-weight: bold; }
  .checkout-cart-index .cart.table-wrapper .items thead .col.availability {
    display: none; }
  .checkout-cart-index .cart.table-wrapper .items .cart.item .item-info .product-item-details .product-brand,
  .checkout-cart-index .cart.table-wrapper .items .cart.item .item-info .product-item-details .product-item-name {
    margin-bottom: 0; }
  .checkout-cart-index .cart.table-wrapper .items .cart.item .item-info .product-item-photo-picto {
    display: none; }
  .checkout-cart-index .cart.table-wrapper .items .cart.item .item-info .col.qty button {
    display: none; }
  .checkout-cart-index .cart.table-wrapper .items .cart.item .item-info .col.qty input {
    border-color: transparent;
    font-weight: normal; }
  .checkout-cart-index .cart.table-wrapper .items .cart.item .item-info .col.availavility {
    display: none; }
  .checkout-cart-index .cart.table-wrapper .items .cart.item .item-info .col.subtotal {
    font-family: Arial, 'Helvetica Neue', sans-serif; }
  .checkout-cart-index .cart.table-wrapper .items .cart.item .item-info dt,
  .checkout-cart-index .cart.table-wrapper .items .cart.item .item-info .price-excluding-tax .price {
    font-weight: normal; }
  .checkout-cart-index .cart.table-wrapper .items .cart.item .actions-toolbar {
    display: none; }
  .checkout-cart-index .dc__products-section_products-table .dc__order-items_wrapper {
    padding: 0 1rem; }
  .checkout-cart-index .dc__products-section_products-table .dc__order-items thead {
    display: table-header-group !important; }
    .checkout-cart-index .dc__products-section_products-table .dc__order-items thead th {
      white-space: nowrap; }
      .checkout-cart-index .dc__products-section_products-table .dc__order-items thead th.qty {
        text-align: center; }
      .checkout-cart-index .dc__products-section_products-table .dc__order-items thead th.subtotal {
        text-align: right; }
  .checkout-cart-index .dc__products-section_products-table .dc__order-items .dc__item-table_row {
    border-width: 0.1rem 0 0;
    border-color: #d2d2d2; }
    .checkout-cart-index .dc__products-section_products-table .dc__order-items .dc__item-table_row-item {
      padding-left: 0; }
      .checkout-cart-index .dc__products-section_products-table .dc__order-items .dc__item-table_row-item_subtotal {
        white-space: nowrap; }
    .checkout-cart-index .dc__products-section_products-table .dc__order-items .dc__item-table_row-delete_text {
      display: none; }
    .checkout-cart-index .dc__products-section_products-table .dc__order-items .dc__item-table_row-availability {
      display: none; }
    .checkout-cart-index .dc__products-section_products-table .dc__order-items .dc__item-table_row-qty {
      text-align: center; }
    .checkout-cart-index .dc__products-section_products-table .dc__order-items .dc__item-table_row-price {
      text-align: right; }
    .checkout-cart-index .dc__products-section_products-table .dc__order-items .dc__item-table_row-promo-text {
      display: none; }
    .checkout-cart-index .dc__products-section_products-table .dc__order-items .dc__item-table_row.d-flex {
      display: table-row; }
      .checkout-cart-index .dc__products-section_products-table .dc__order-items .dc__item-table_row.d-flex td.dc__item-table_row-promo-badge.fullwidth {
        display: block;
        box-sizing: border-box;
        clear: both; }
  .checkout-cart-index .dc__products-section_products-table [data-update-qty]:not([data-update-qty='enter']) {
    display: none; }
  .checkout-cart-index .dc__products-section_products-table .dc__form-quantity-picker_input[type='number'] {
    border: none; }
  .checkout-cart-index .dc__progress-bar,
  .checkout-cart-index .product-left-container,
  .checkout-cart-index .cart-wrapper_description,
  .checkout-cart-index .dc__empty-cart-link,
  .checkout-cart-index .dc__item-table_row-delete {
    display: none; }
  .checkout-cart-index .cart-wrapper {
    padding: 2rem 0 0; }
    .checkout-cart-index .cart-wrapper .cart-wrapper_header .cart-wrapper_description,
    .checkout-cart-index .cart-wrapper .cart-wrapper_header .quick-order {
      display: none; }
    .checkout-cart-index .cart-wrapper .cart-wrapper-inner {
      margin-top: 2rem; }
  .checkout-cart-index .dc__block_title {
    display: none; }
  .checkout-cart-index .dc__dashboard_info {
    display: flex !important; }
  .checkout-cart-index .dc__dashboard_container {
    width: 100%; }
    .checkout-cart-index .dc__dashboard_container_content {
      display: flex;
      flex-direction: row-reverse;
      column-gap: 1rem; }
      .checkout-cart-index .dc__dashboard_container_content .dc__box_content_title {
        font-size: 1.6rem; }
  .checkout-cart-index .dc__box_content_custom {
    flex: 1;
    padding: 1rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.12);
    border-radius: 0.3rem; }
  .checkout-cart-index .dc__box_content_text_mobile-phone {
    display: none; } }

@media print {
  .catalog-product-view .column.main .related-products-container {
    display: none; }
  .catalog-product-view .column.main .product-info-main-wrapper {
    flex-basis: 10.2rem;
    margin-left: auto; }
    .catalog-product-view .column.main .product-info-main-wrapper .product-info-price-container {
      box-shadow: none;
      border: 0.1rem solid #dcdee2;
      border-radius: 0.3rem; }
    .catalog-product-view .column.main .product-info-main-wrapper .product-info-price-banner {
      padding: 5px 10px 5px 0.8rem;
      font-size: 0.8rem;
      line-height: 1.3rem;
      letter-spacing: -0.005rem;
      color: #191c1f;
      border-bottom: 0.1rem solid #d2d2d2;
      text-transform: none;
      font-weight: 400; }
    .catalog-product-view .column.main .product-info-main-wrapper .product-info-price-wrapper {
      padding: 10px; }
      .catalog-product-view .column.main .product-info-main-wrapper .product-info-price-wrapper .product-info-price {
        flex-direction: column; }
        .catalog-product-view .column.main .product-info-main-wrapper .product-info-price-wrapper .product-info-price .price-container {
          margin-right: 0;
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 2.1rem;
          color: #2a2a2a; }
        .catalog-product-view .column.main .product-info-main-wrapper .product-info-price-wrapper .product-info-price .unit {
          margin-top: 0.6rem;
          color: #515151;
          font-size: 1.2rem;
          line-height: 1.3rem;
          display: block; }
          .catalog-product-view .column.main .product-info-main-wrapper .product-info-price-wrapper .product-info-price .unit .conditioning {
            opacity: 1;
            color: inherit;
            font-size: inherit;
            line-height: 1.3rem;
            margin: 0; }
        .catalog-product-view .column.main .product-info-main-wrapper .product-info-price-wrapper .product-info-price__public-price {
          font-weight: 300;
          color: #6d6d6d !important;
          font-size: 0.8rem;
          line-height: 1.3rem; }
  .catalog-product-view .dc__productBrand {
    margin-bottom: 5px;
    line-height: 1.7rem; }
    .catalog-product-view .dc__productBrandTitle {
      text-decoration: none !important;
      color: #6d6d6d !important;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.7rem;
      text-transform: uppercase; }
  .catalog-product-view .product-info-custom {
    display: flex;
    flex-direction: row;
    flex: none !important;
    height: auto;
    margin-top: 1.5rem; }
    .catalog-product-view .product-info-custom-container {
      box-shadow: none;
      margin-top: 1.5rem;
      padding-top: 0;
      padding-bottom: 0; }
    .catalog-product-view .product-info-custom-header .dc__actions {
      display: none; }
    .catalog-product-view .product-info-custom-header .page-title {
      color: #2a2a2a;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2.1rem; }
    .catalog-product-view .product-info-custom-header .gtm-product-info > .product + .product,
    .catalog-product-view .product-info-custom-header .product-head-wrapper > .product + .product {
      margin-top: 0.5rem; }
    .catalog-product-view .product-info-custom-header .gtm-product-info .attribute,
    .catalog-product-view .product-info-custom-header .product-head-wrapper .attribute {
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1.3rem;
      color: #2a2a2a; }
    .catalog-product-view .product-info-custom-header .references .separator {
      font-weight: 300;
      font-size: 0.8rem;
      line-height: 1.3rem;
      color: #6d6d6d !important; }
    .catalog-product-view .product-info-custom .product-brand-logo {
      display: none; }
    .catalog-product-view .product-info-custom .references {
      flex-direction: column; }
      .catalog-product-view .product-info-custom .references .ref {
        margin-left: 0; }
    .catalog-product-view .product-info-custom .product-info-price-container-container {
      box-shadow: none; }
    .catalog-product-view .product-info-custom .gallery-placeholder {
      width: 19rem;
      max-width: 19rem;
      height: 19rem;
      margin: 0 2.1rem 0 0;
      padding: 0;
      flex: 0 0 19rem !important;
      border: 0.1rem solid #dcdee2;
      border-radius: 0.3rem; }
      .catalog-product-view .product-info-custom .gallery-placeholder .fotorama-item {
        height: 100%; }
      .catalog-product-view .product-info-custom .gallery-placeholder .fotorama__wrap {
        height: 100%; }
      .catalog-product-view .product-info-custom .gallery-placeholder .fotorama__nav-wrap,
      .catalog-product-view .product-info-custom .gallery-placeholder .fotorama__nav-wrap--horizontal {
        display: none; }
      .catalog-product-view .product-info-custom .gallery-placeholder .fotorama__stage {
        position: relative;
        width: 100% !important;
        height: 100% !important;
        max-width: 16rem !important;
        max-height: 15rem !important;
        padding-bottom: 0 !important; }
        .catalog-product-view .product-info-custom .gallery-placeholder .fotorama__stage .fotorama__stage__shaft {
          max-width: 19rem;
          height: 100%; }
          .catalog-product-view .product-info-custom .gallery-placeholder .fotorama__stage .fotorama__stage__shaft .fotorama__img {
            position: static;
            transform: none; }
        .catalog-product-view .product-info-custom .gallery-placeholder .fotorama__stage__frame {
          align-items: flex-start;
          min-height: 19rem !important; }
      .catalog-product-view .product-info-custom .gallery-placeholder .fotorama__stage:after {
        display: none; }
    .catalog-product-view .product-info-custom .gallery-placeholder,
    .catalog-product-view .product-info-custom .product-head-wrapper {
      flex: 1; }
    .catalog-product-view .product-info-custom .dc__attribut_data {
      display: none; }
  .catalog-product-view .dc__discriminant {
    margin-right: 20px; }
    .catalog-product-view .dc__discriminant__header {
      display: none; }
    .catalog-product-view .dc__discriminant__form {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-bottom: -10px; }
    .catalog-product-view .dc__discriminant__parameter_container {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      margin-left: 10px; }
    .catalog-product-view .dc__discriminant__parameter_label {
      font-weight: 700;
      color: #2a2a2a; }
      .catalog-product-view .dc__discriminant__parameter_label_container {
        display: flex;
        margin: 0; }
      .catalog-product-view .dc__discriminant__parameter_label_unit::after, .catalog-product-view .dc__discriminant__parameter_label_unit::before {
        display: inline-block !important;
        content: ' ';
        white-space: pre; }
    .catalog-product-view .dc__discriminant__parameter_list {
      display: inline;
      margin: 0; }
    .catalog-product-view .dc__discriminant__parameter_item {
      margin: 0; }
    .catalog-product-view .dc__discriminant__parameter_button {
      padding: 0;
      border: none;
      box-shadow: none !important;
      height: auto; }
      .catalog-product-view .dc__discriminant__parameter_button:not(.selected) {
        display: none; }
      .catalog-product-view .dc__discriminant__parameter_button::before {
        display: inline-block !important;
        content: ': ';
        white-space: pre; }
    .catalog-product-view .dc__discriminant__parameter_list, .catalog-product-view .dc__discriminant__parameter_item, .catalog-product-view .dc__discriminant__parameter_label, .catalog-product-view .dc__discriminant__parameter_label_unit, .catalog-product-view .dc__discriminant__parameter_button {
      font-size: 0.8rem;
      line-height: 1.3rem; }
  .catalog-product-view .product-info-custom .product-info-main__add-cart-container,
  .catalog-product-view .product-info-custom .product-store-more,
  .catalog-product-view .product-info-custom .product.share,
  .catalog-product-view .product-info-custom .product.print {
    display: none; }
  .catalog-product-view .product-left-container {
    width: 100%;
    margin-top: 0; }
    .catalog-product-view .product-left-container > div + div {
      margin-top: 2rem; }
    .catalog-product-view .product-left-container .dc__description {
      font-size: 1rem;
      line-height: 1.5;
      min-height: 0;
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 0; }
    .catalog-product-view .product-left-container .dc__title-section {
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.7rem;
      text-transform: uppercase;
      color: #6d6d6d !important;
      margin-bottom: 10px; }
      .catalog-product-view .product-left-container .dc__title-section span {
        display: inline;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        color: inherit !important; }
    .catalog-product-view .product-left-container .product-infos-container .dc__attribut_data {
      width: 100%;
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
      .catalog-product-view .product-left-container .product-infos-container .dc__attribut_data .attributes--overflow {
        overflow: visible; }
      .catalog-product-view .product-left-container .product-infos-container .dc__attribut_data form {
        flex: 1; }
    .catalog-product-view .product-left-container .product-infos-container .dc__limit_action,
    .catalog-product-view .product-left-container .product-infos-container .dc__documentation {
      display: none; }
    .catalog-product-view .product-left-container .dc__product_page_title {
      border-bottom: none;
      font-size: 2rem;
      padding-bottom: 0;
      margin: 2rem 0; }
    .catalog-product-view .product-left-container .attributes {
      height: auto !important; }
      .catalog-product-view .product-left-container .attributes > li .attribute dt,
      .catalog-product-view .product-left-container .attributes > li .attribute dd {
        padding: 0;
        font-size: 1rem;
        line-height: 1.5; }
      .catalog-product-view .product-left-container .attributes > li .attribute dt {
        font-weight: 500; }
      .catalog-product-view .product-left-container .attributes > li .attribute dd {
        font-weight: 300;
        color: #3c3d3e !important; }
      .catalog-product-view .product-left-container .attributes > li + li {
        margin-top: 10px; }
    .catalog-product-view .product-left-container .product.anchor,
    .catalog-product-view .product-left-container .dc_more-details {
      display: none; } }
