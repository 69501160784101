$color-main-dark: #191c1f;
$color-main-light: #fff;

$color-error: #e30324;
$color-warning: #c04d28;
$color-success: #008800;

$color-primary: #333333;
$color-primary-darken: shade($color-primary, 20%);
$color-secondary: #e83e30; // warning: no a11y compliance
$color-secondary-darken: shade($color-secondary, 20%);
$color-sub-secondary: #99342b;
$color-dark-grey: #515151;
$color-tertiary: #447d7d;
$color-accent: $color-primary;
$color-accent-darken: shade($color-accent, 20%);
$color-pickup: $color-primary;

$color-blue-dark: $color-secondary-darken; // used in few link on hover element state
$color-blue-light: #dcdee2; // used in few background-color and border-color

$color-tertiary-11: #7cc5b9; // map with dark foreground

$color-disabled-dark: #666;
$color-disabled-light: #d2d2d2; // Same as $color-gray83

$color-background-default: $color-main-light;
$color-text-default: $color-main-dark;
$color-text-light: #7f7f7f; // TODO design toolkit (+a11y contrast issue)
$color-text-invert: $color-main-light;

$gamme-list: (); // No colors by categories in Dexis

$color-scrollbar-track: #efefef; // TODO: use $color-white-dark-smoke instead
$color-scrollbar-bar: #b9b9b9;

$color-sidebar-customer-name: $color-secondary;
$color-sidebar-customer-account-number: $color-primary;

$color-sidebar-account-management: $color-secondary;

$color-info-price-banner: $color-tertiary;
$color-info-price-banner-background: $color-tertiary;
$color-info-price-border: $color-tertiary;
$color-info-price-qty-text: $color-primary;
$color-info-price-promo: $color-info-price-banner;
$color-info-price-qty-background: #f5f8ff; // Todo : remove as it seems not used
$color-qty-background: $color-primary-darken; // Todo : remove as it seems not used

$color-contact-faq-arrow: $color-primary;
$color-input-autocomplete-search-btn: $color-primary;
$color-input-autocomplete-search-border: $color-primary-darken;

// For resize purposes, the shape has been done in CSS instead of a SVG, which requires to define the background color.
$color-loyalty-shape-background: #def2f8;

$color-menu-mobile-default: $color-secondary;
$color-menu-mobile-links: $color-secondary;
$color-form-border: $color-primary;
$color-form-arrow: $color-primary;
$color-form-search-submit-background: $color-primary;
$color-form-search-submit-background-darken: $color-primary-darken;

$color-white-smoke-2: #e7edf5; // Todo : need to be a variable in figma for easier toolkit mapping

$color-checkout-step-active: $color-primary;
$color-checkout-delivery-online: $color-primary;
$color-checkout-delivery-pickup: $color-checkout-delivery-online;
$color-checkout-summary-delivery: $color-secondary;
$color-checkout-summary-delivery-click-collect: $color-secondary;
$color-checkout-payment-label: $color-primary;

$color-pagination-step-current-background: $color-secondary;

$color-cms-brand-index-link: $color-primary;
$color-cms-brand-index-title: $color-primary;

$color-availability-closed: $color-error;

$color-useraccount-tag-border: $color-blue-light;
$color-useraccount-tag-background: $color-primary;

$color-product-header-delivery-online-background: $color-secondary;
$color-product-header-delivery-pickup-background: $color-checkout-delivery-pickup;

$color-order-status-pending: $color-primary;

$color-link-hover: $color-secondary;

$color-search-layer-icons: $color-primary;
